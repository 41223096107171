interface VimConfig {
  clientId: string;
  clientSecret: string;
  host: string;
  httProtocol: string;
  wsProtocol: string;
  scope: string;
  httPort: number;
  wsPort: number;
}

const vimConfig: VimConfig = {
  clientId: "app",
  clientSecret: "app",
  host: "192.168.88.88",
  // host: "chat.123ai.pro",
  httProtocol: "https",
  wsProtocol: "wss",
  scope: "server",
  httPort: 8088,
  wsPort: 9326,
};
// console.log('host'+vimConfig.host)
// console.log(window.location.origin)

if (window.location.origin.indexOf("chat.123ai.pro") > -1) {
  vimConfig.host="chat.123ai.pro/api"
} 
// else if(window.location.origin.indexOf("http://114.242.3.137:8888/api/") > -1){
//     vimConfig.host = 'http://114.242.3.137:8888/api/'
// }

export default vimConfig;
