import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/index",
    name: "Home",
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "chat",
        name: "chatBox",
        // component: () => import("../views/chat/Index.vue"),//暂时隐藏
        component: () => import("../views/chatGTP/Index.vue"),
      },
      // {
      //   path: "friend",
      //   name: "friendBox",
      //   component: () => import("../views/friend/Index.vue"),
      //   children: [
      //     {
      //       path: ":id",
      //       name: "user",
      //       component: () => import("../views/friend/UserInfo.vue"),
      //     },
      //   ],
      // },
      {
        path: "dept",
        name: "deptBox",
        component: () => import("../views/dept/Index.vue"),
        children: [
          {
            path: "new",
            name: "new",
            component: () => import("../views/dept/New.vue"),
          },
          {
            path: ":id",
            name: "dept",
            component: () => import("../views/dept/DeptInfo.vue"),
          },
        ],
      },
      {
        path: "aiVoice",
        name: "aiVoiceBox",
        // component: () => import("../views/group/Index.vue"),
        component: () => import("../views/aiVoice/index.vue"),
     
      },
     
    //   {
    //     //AI视频剪辑,字幕编辑
    //     path: "aiApply",
    //     name: "aiApplyBox",
    //     component: () => import("../views/captionsBox/captions.vue"),
    //     // children: [
    //     //   {
    //     //     path: "captions",
    //     //     name: "captions",
    //     //     component: () =>
    //     //       import("../views/aiApply/captionsBox/captions.vue"),
    //     //   },
    //     // ],
    //   },
      {
        //AI视频剪辑,字幕编辑
        path: "aiApply",
        name: "aiApplyBox",
        component: () => import("../views/captions/index.vue"),
        children: [
            {
                path: "project",
                name: "project",
                component: () =>
                  import("../views/captions/project.vue"),
              },
            {
                path: "captionsEdit",
                name: "captionsEdit",
                component: () =>
                import("../views/captions/captionsEdit.vue"),
            },
            {
                path: "projectList",
                name: "projectList",
                component: () =>
                import("../views/captions/projectList.vue"),
            },
            {
              path: "contrast",
              name: "contrast",
              // component: () => import("../views/group/Index.vue"),
              component: () => import("../views/captions/captionContrast.vue"),
           
            },
        ],
      },
        //  {
        //     path: "captionsEdit",
        //     name: "captionsEdit",
        //     component: () =>
        //       import("../views/captions/captionsEdit.vue"),
        //   },
      {
        path: "system",
        name: "systemBox",
        component: () => import("../views/sys/Index.vue"),
        children: [
          {
            path: "user",
            name: "sys-user",
            component: () => import("../views/sys/user/index.vue"),
          },
          {
            path: "pwd",
            name: "pwd",
            component: () => import("../views/sys/pwd/index.vue"),
          },
        
        ],
      },
    ],
  },
  {
    path: "/",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/Register.vue"),
  },
];
// 这里必须是createWebHashHistory模式，也就是 hash 模式，否则打包完成是白板。
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
