import FetchRequest from "@/api/FetchRequest";
class newCaptionApi {
    //基础url
    static url = "/ai/subtitle/";
    // 新增AI字幕
   
    static addAi( hotWord: string, language: string,maximumWordCount: number,parentId:number,title:string,translationLanguage:string): Promise<any> {

        const data={
            hotWord:hotWord,
            language:language,
            maximumWordCount:maximumWordCount,
            parentId:parentId,
            title:title,
            translationLanguage:translationLanguage,

        }
        return FetchRequest.post(this.url + 'ai' ,JSON.stringify(data) , true);
    }

    // 字幕列表
    static getList(title:string,pageNum: number, pageSize: number,parentId:number): Promise<any> {
        return FetchRequest.get(this.url + 'list'+'?title='+title+'&pageNum=' + pageNum + '&pageSize=' + pageSize+ '&parentId=' + parentId ,true );
    }
    // 新建文件夹
    static addFolder(folderName:string,parentId:number): Promise<any> {
        const data={
            folderName:folderName,
            parentId:parentId,
        }
        return FetchRequest.post(this.url + 'folder' ,JSON.stringify(data) , true);
    }
    // 删除
    static delText(subtitleId: string): Promise<any> {
       
        return FetchRequest.del(this.url +subtitleId,'' , true);
    }
    // 修改
    static putOne(subtitleId:string,parentId: string,title: string): Promise<any> {
        const data={
            parentId:parentId,
            title:title
        }
        return FetchRequest.put(this.url + subtitleId ,JSON.stringify(data) , true);
    }
    // 删除文件夹
    static delFolder(id: string): Promise<any> {
       
        return FetchRequest.del(this.url + 'folder/'+id,'' , true);
    }
     // 修改文件夹
     static putFolderOne(id:string,parentId: string,folderName: string): Promise<any> {
        const data={
            parentId:parentId,
            folderName:folderName
        }
        return FetchRequest.put(this.url +'folder/'+ id ,JSON.stringify(data) , true);
    }
    // 获取所有目录
    static getFolderList(): Promise<any> {
        return FetchRequest.get(this.url + 'folder/all' ,true );
    }
    // 字幕详情（初始化版本）
    static getInitCaption(subtitleId: number): Promise<any> {
        return FetchRequest.get(this.url + 'detail/'+subtitleId ,true );
    }
    
    // 修改字幕详情()
    static putInitOneRule(subtitleId:string,checkRule?:object): Promise<any> {
        const data={
            checkRule:checkRule 
        }
        
           
        return FetchRequest.put(this.url +'detail/'+ subtitleId ,JSON.stringify(data) , true);
    }
    static putInitOneroles(subtitleId:string,roles: any): Promise<any> {
        const data={
                roles:roles 
            }
       
        return FetchRequest.put(this.url +'detail/'+ subtitleId ,JSON.stringify(data) , true);
    }
    static putInitOnetitle(subtitleId:string,title: string): Promise<any> {
        const data={
            title:title 
        }
        return FetchRequest.put(this.url +'detail/'+ subtitleId ,JSON.stringify(data) , true);
    }
    // 字幕详细信息(编辑版本)
    static getEditCaption(ids: number): Promise<any> {
        return FetchRequest.get(this.url + 'text/'+ids ,true );
    }
    // 修改字幕详情
    static putEditCaption(textId:string,data: object,id:string,line:number,type:string): Promise<any> {
        const datas={
            data:data,
            id:id, 
            line:line,
            type:type,
        }
        return FetchRequest.put(this.url +'text/edit/'+ textId ,JSON.stringify(datas) , true);
    }
    // 字幕所有版本
    static getCaptionVersion(subtitleId: number): Promise<any> {
        return FetchRequest.get(this.url + 'text/all/'+subtitleId ,true );
    }
    
    
    // 发布版本
    static release( cover: boolean, originalTextId: number,releaseNote: string,version:string): Promise<any> {
        const data={
            cover:cover,
            originalTextId:originalTextId,
            releaseNote:releaseNote,
            version:version,
        }
        return FetchRequest.post(this.url + 'text/release' ,JSON.stringify(data) , true);
    }
    // 翻译
    static addTextEn(textId: string,to:string,from:string): Promise<any> {
        
        return FetchRequest.post(this.url + 'text/translate/'+textId+'?to='+to+'&from='+from,'' , true);
    }
    // 删除译文
    static delTextEn(textId:string): Promise<any> {
        
        return FetchRequest.del(this.url + 'text/translation/'+textId ,"", true);
    }
    
    // 简繁转换
    static textConvert(id: string): Promise<any> {
       
        return FetchRequest.put(this.url +'text/simTraConvert/'+id , '', true);
    }

    // 音波信息
    static getwave(id: string,samplesPerPixel: string,startSample: string,endSample: string): Promise<any> {
      
        return FetchRequest.get(this.url + 'detail/wave/'+id +'?samplesPerPixel='+samplesPerPixel+'&startSample='+startSample+'&endSample='+endSample,true,false);
    }
      // 分享
    static shareTo(subtitleId: string,cellphone:string): Promise<any> {
        const data={
            subtitleId: subtitleId,
            cellphone: cellphone
        }
        return FetchRequest.post('/ai/subtitle/share'+'?subtitleId='+subtitleId+'&cellphone='+cellphone,'', true);
    }
    // 接受分享
    static sharePut(subtitleId: string): Promise<any> {
        
        return FetchRequest.put( '/ai/subtitle/share/accept'+'?subtitleId='+subtitleId ,'', true);
    }
    // 取消-拒绝分享
    static cancelsharePut(subtitleId: string): Promise<any> {
        
        return FetchRequest.put( '/ai/subtitle/share/cancel'+'?subtitleId='+subtitleId ,'', true);
    }
    // 
    static myCancelsharePut(shareId: string): Promise<any> {
        
        return FetchRequest.put( '/ai/subtitle/share/cancel/'+shareId ,'', true);
    }
    // 查询所有分享
    static shareList(subtitleId: string): Promise<any> {
        
        return FetchRequest.get( '/ai/subtitle/share/all'+'?subtitleId='+subtitleId , true);
    }
    // 导出字幕
    static TextDownlod(textId: string,contents: string,ext: string): Promise<any> {
        const data={
            textId:textId,
            contents:'',
            ext:''
        }
        return FetchRequest.get('/ai/subtitle/text/export'+'?textId='+textId +'&contents='+contents+'&ext='+ext,true,true);
    }

}
export default newCaptionApi;